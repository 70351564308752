export const SET_USER = 'SET_USER';
export const RESET_USER = 'RESET_USER';
export const SET_EMAIL = 'SET_EMAIL';
export const SET_DASHBOARD_REPORT_TABLE = 'SET_REPORT_TABLE';
export const SET_INSTITUTIONS_TABLE = 'SET_INSTITUTIONS_TABLE';
export const SET_MY_REPORTS_TABLE = 'SET_MY_REPORTS_TABLE';
export const SET_REPORTS_TABLE = 'SET_REPORTS_TABLE';
export const SET_AGENCIES_TABLE = 'SET_AGENCIES_TABLE';
export const SET_MY_AGENCIES_TABLE = 'SET_AGENCIES_TABLE';
export const SET_AGENCY_ACTIVITIES_TABLE = 'SET_AGENCY_ACTIVITIES_TABLE';
export const SET_COUNTRIES_TABLE = 'SET_COUNTRIES_TABLE';
export const SET_REPORT_FLAGS_TABLE = 'SET_REPORT_FLAGS_TABLE';

export const TOGGLE_INSTITUTIONS_TABLE_FILTER = 'TOGGLE_INSTITUTIONS_TABLE_FILTER';
export const TOGGLE_REPORTS_TABLE_FILTER = 'TOGGLE_REPORTS_TABLE_FILTER';
export const TOGGLE_MY_REPORTS_TABLE_FILTER = 'TOGGLE_MY_REPORTS_TABLE_FILTER';
export const TOGGLE_AGENCIES_TABLE_FILTER = 'TOGGLE_AGENCIES_TABLE_FILTER';
export const TOGGLE_AGENCY_ACTIVITIES_TABLE_FILTER = 'TOGGLE_AGENCY_ACTIVITIES_TABLE_FILTER';
export const TOGGLE_COUNTRIES_TABLE_FILTER = 'TOGGLE_COUNTRIES_TABLE_FILTER';
export const TOGGLE_REPORT_FLAGS_TABLE_FILTER = 'TOGGLE_REPORT_FLAGS_TABLE_FILTER';

